.select {
    @apply ring-gray-10 hover:ring-primary-hover focus:ring-primary-base relative flex w-full cursor-default items-center gap-2.5 rounded-full py-2 pl-4 pr-2 leading-6 outline-none ring-1 ring-inset transition-all focus:ring-inset;
}
.caret {
    @apply border-gray-10 flex h-12 w-12 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border transition-all;
}
.select:hover .caret {
    @apply border-primary-hover;
}
.select:focus .caret {
    @apply border-primary-base;
}
.optionsContainer {
    box-shadow: 0 29px 17px 0 rgba(0, 0, 0, 0.08);
    @apply absolute z-40 mt-1.5 max-h-60 w-full list-none overflow-auto rounded-lg bg-white p-1 py-1 text-base focus:outline-none;
}
.option {
    @apply hover:bg-primary-20 block cursor-pointer rounded-full px-1.5 py-1 transition-colors;
}
