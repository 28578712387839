.bank {
    @apply mb-0;
}
.itemInner {
    @apply bg-primary-20 hover:bg-primary-hover flex min-h-16 cursor-pointer items-center gap-2 rounded-full py-2 px-4 transition-colors hover:text-white border border-transparent;
}
.itemInner:has(img) {
    @apply pl-2;
}
.bank input:checked + .itemInner {
    @apply bg-primary-hover text-white;
}
