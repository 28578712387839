.item {
    @apply block h-16 w-1/6 rounded-full bg-gray-200;
}
.item:nth-child(2n) {
    @apply w-1/5;
}
.item:nth-child(3n) {
    @apply w-1/4;
}
.item:nth-child(4n) {
    @apply w-1/5;
}
