.inputWrapper {
    @apply relative;
}
.inputWrapper :is(input[type='number']) {
    -moz-appearance:textfield;
}
.inputWrapper input::-webkit-outer-spin-button,
.inputWrapper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.inputLeftIcon input {
    @apply !ps-12;
}
.inputLeftIcon input:not(:placeholder-shown) + .leftIcon {
    @apply text-primary-base;
}
.iconWrapper {
    @apply ring-gray-10 absolute right-2 top-1/2 flex h-12 w-12 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full ring-1;
}
.leftIcon {
    @apply text-text-secondaryLight absolute left-4 top-1/2 flex -translate-y-1/2 items-center justify-center transition-colors;
}
