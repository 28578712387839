.dialogContent {
    @apply relative w-full transform overflow-hidden rounded-2xl bg-white px-4 py-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-8;
}
.size-xs {
    @apply sm:max-w-[420px];
}
.size-sm {
    @apply sm:max-w-md;
}
.size-md {
    @apply sm:max-w-xl;
}
.size-lg {
    @apply sm:max-w-[946px];
}
