.wrapper {
    @apply col-span-6 sm:col-span-3;
}

.wrapper-xs {
    @apply sm:col-span-1;
}

.wrapper-s {
    @apply sm:col-span-2;
}

.wrapper-l {
    @apply sm:col-span-4;
}

.wrapper-xl {
    @apply sm:col-span-6;
}

.wrapper-full {
    @apply col-span-full;
}

.label {
    @apply text-text-primary block pb-2 text-base font-semibold;
}

.innerControl {
    @apply relative flex flex-grow flex-col;
}

.innerControl:has(input[type="radio"]) {
    @apply flex-row gap-8 flex-wrap;
}

.innerControl :is(input:not([type="radio"]), textarea, select) {
    @apply text-text-primary ring-gray-10 placeholder:text-text-secondaryLight hover:ring-primary-hover focus:ring-primary-base block w-full rounded-full border-none px-4 py-5 font-medium leading-6 outline-none ring-1 ring-inset transition-all focus:ring-inset;
}

.innerControl input[type="radio"] {
    @apply text-primary-base outline-primary-hover focus:ring-primary-hover;
}

.innerControl input {
    @apply placeholder-shown:text-ellipsis;
}

.innerControlError :is(input:not([type="radio"]), textarea, select) {
    @apply text-error ring-error  hover:ring-error focus:ring-error block w-full ring-1 ring-inset;
}

.innerControlError svg {
    @apply text-error;
}

.messageInfo {
    @apply text-text-secondary mb-0 mt-2 inline-block text-base;
}

.messageError {
    @apply text-text-secondary mt-2 text-base;
}
