.h1 {
    @apply text-3xl font-semibold lg:text-5xl;
}
.h2 {
    @apply text-3xl font-semibold leading-tight;
}
.h3 {
    @apply text-2xl font-semibold;
}
.h4 {
    @apply text-xl font-semibold;
}
.h5 {
    @apply text-xl;
}
.h6 {
    @apply text-base font-medium;
}
.p {
    @apply text-base;
}
