.button {
    @apply rounded-full transition-colors;
}
.button.primary {
    @apply bg-primary-base hover:bg-primary-hover active:bg-primary-active disabled:bg-primary-disabled focus-visible:outline-primary-active text-white;
}
.button.secondary {
    @apply bg-secondary-base hover:bg-secondary-hover active:bg-secondary-active disabled:bg-secondary-disabled rounded-full text-white focus-visible:outline-secondary-active;
}
.button.outline {
    @apply border-secondary-base hover:border-secondary-hover active:border-outline-active active:bg-outline-active disabled:border-secondary-disabled disabled:text-text-secondary border bg-transparent active:text-white disabled:bg-transparent focus-visible:outline-secondary-active;
}
.button.white {
    @apply text-text-primary hover:text-primary-base active:bg-primary-100 active:text-primary-base bg-white disabled:opacity-20 focus-visible:outline-stroke-50;
}
.button.link {
    @apply text-primary-base hover:text-primary-hover active:text-primary-active !inline-block !p-0;
}
.button.sm {
    @apply px-3 py-2 text-sm font-normal leading-4;
}
.button.md {
    @apply px-4 py-[.8125rem] text-base font-medium;
}
.button.lg {
    @apply px-10 py-5 text-sm font-semibold leading-6;
}
.button.icon {
    @apply inline-flex items-center gap-x-1.5 py-[.8125rem];
}
.button.loading {
    @apply inline-flex items-center justify-center gap-x-1.5;
}
.secondary.loading > div {
    @apply border-white;
}
.button.loading > div + svg {
    @apply hidden;
}
.button.square {
    @apply flex h-12 w-12 items-center justify-center p-0;
}
